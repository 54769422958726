@use './config/' as *;

.waf-coach {
    padding-top: 0;

    .layout-wrapper {
        max-width: var(--container-max-width);
        margin: 0 auto;
    }

    .coach-wrap {
        .title {
            font: 2.6rem/1.4 $font-bold;
        }
    }

    .coach-item {
        padding-bottom: calc(3 * var(--full-space));
        border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
        &:not(:first-child){
            margin: calc(var(--full-space) * 2) 0;
        }
    }


    .coach-image {
        background: url('/static-assets/images/coach/coach-bg-m.png?v=#{$image-version}') no-repeat bottom;
        background-size: 100%;
        padding: 0 calc(var(--full-space) * 2);
        height: 33rem;
        @include flex-config(flex, null, center, flex-end);

        img {
            display: block;
            height: 100%;
            object-fit: contain;

        }

        .img-wrap {
            height: 100%;
        }

    }

    .about-coach {
        font: 1.4rem/1.7 $font-medium;
    }

    .coach-name {
        margin: var(--full-space) 0;
    }

    .coach-details {

        padding: var(--full-space);
    }

    .coach-name {
        padding: var(--full-space);
        margin: 0;
        text-transform: uppercase;
    }

    .head-coach {
        .name {
            font: 2.4rem/1.6 $font-bold;
            color: var(--kabaddi-accent-color);
        }

        .coach-body {
            margin-top: 3rem;
            background: var(--light-grey-color);
        }

        .img-wrap {
            height: auto;
        }

        .coach-image {
            height: 22rem;


            img {
                display: block;
                height: 100%;
                width: 80%;
                max-width: 23rem;
                margin: 0 auto;
            }
        }


        .coach-details {

            padding-top: 6rem;
            margin-top: -7rem;
            background: var(--light-grey-color);
            @include box-shadow(0 .6rem 1rem 0 rgba(0, 0, 0, 0.25));
            @include border-radius(1rem);
        }
    }

    .assistant-coach {

        .name {
            font: 2rem/1.6 $font-bold;
            color: var(--primary-color);
            display: block;
        }

        .coach-body {
            flex-wrap: wrap;
            margin-top: var(--full-space);
            @include flex-config(flex, null, null, stretch);
        }

        .coach-image {
            width: 12rem;
            height: 10rem;
            padding: 0 calc(var(--full-space) / 2);
            flex-shrink: 0;
            position: relative;
            z-index: 2;
            @include flex-config(flex, null, center, center);

            .img-wrap {
                height: 100%;
                @include flex-config(flex, null, null, null);
            }

            img {
                height: auto;
                margin-top: -1rem;
                object-fit: contain;
            }


        }

        .coach-name {
            border: 0.1rem solid rgba(var(--black-color-rgb), 0.3);
            background-color: rgba(var(--black-color-rgb), .1);
            width: calc(100% - 12rem + 1rem);
            padding-left: 2rem;
            margin-left: -1rem;
        }

        .coach-details {

            border-top: 0;
            background: url('/static-assets/images/coach/rect-bg.png?v=#{$image-version}') bottom right;
            background-size: cover;
            border-left: .1rem solid rgba(var(--black-color-rgb), .1);
        }


    }

}

@media (min-width:$tablet-min-width) {
    .waf-coach {

        .coach-item {
            position: relative;
        }

        .head-coach {
            .coach-head {
                @include position(8%, null, null, null);

            }

            .name {
                font: 2.4rem/1.6 $font-bold;

            }

            .img-wrap {
                margin-bottom: -0.75rem;
            }

            .coach-body {
                position: relative;
                background: transparent;
                margin-top: 0;
                @include flex-config(flex, row-reverse, null, flex-end)
            }

            .coach-image {
                width: 30rem;
                height: 35rem;
                flex-basis: 30rem;
                flex-shrink: 0;
                order: 1;
                padding-bottom: var(--half-space);
        margin-top: 2rem;

                background-image: url('/static-assets/images/coach/coach-bg-d.png?v=#{$image-version}');
                @include flex-config(flex, null, center, flex-end);

                img {
                    width: 100%;
                }
            }

            .coach-details {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                height: 26rem;
                order: 2;
                margin-right: -4rem;
                padding-right: 4rem;
                padding-top: var(--full-space);
                background: linear-gradient(89.82deg, var(--light-grey-color) 28.65%, rgba(var(--white-color-rgb), 0) 94.07%);
            }

            .about-coach {
                margin-top: 4rem;
                overflow: auto;
                height: 16rem;
                margin-right: calc(var(--full-space) *2);
                padding: 0 calc(var(--full-space) * 4);

                &::-webkit-scrollbar {
                    width: .5rem;
                }

                &::-webkit-scrollbar-button {
                    width: 1rem;
                    background-color: var(--grey-color);

                    @include border-radius(1rem);
                }

                &::-webkit-scrollbar-track {
                    background-color: var(--black-color);
                    @include border-radius(1rem);
                }

                .text {
                    margin: var(--full-space) 0;
                    font-size: 1.6rem;
                }
            }

            .coach-name {
                order: 3;
                padding: 0;
                margin-left: calc(var(--full-space) * 5);
                @include position(35%, null, null, 0);
            }


        }

    }
}

@media (min-width:$desktop-min-width) {
    .waf-coach {

        .assistant-coach {

            .coach-body {
                border-top: .1rem solid rgba(var(--black-color-rgb), .1);
                background: url('/static-assets/images/coach/bg-rect.png?v=#{$image-version}') bottom right;
                background-size: cover;
                flex-wrap: nowrap;
                @include flex-config(flex, row, null, center);
                height: 14.7rem;
                margin-top: 2rem;
            }

            .coach-name,
            .coach-details {
                background: unset;
                border: unset;

            }

            .coach-image {
                width: 17rem;
                height: 14.7rem;
                justify-content: center;

                img {
                    margin-top: 0;
                    height: 100%;
                }
            }

            .coach-name {
                align-self: center;
                margin: 0;
                padding: calc(2 * var(--full-space));
                border-right: .1rem solid rgba(var(--black-color-rgb), .1);
            }

            .coach-details {
                padding: var(--full-space) calc(3 * var(--full-space)) var(--full-space) calc(2 * var(--full-space));
                width: 65%;
                flex-shrink: 0;
            }
        }
    }
}